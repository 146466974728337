import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'
// import { dateFormat, time12FormateTraining } from '@/Utils/fliter'
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, detailsData, dateList) => {
  try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [
          {
            columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: reportHeadData.address, style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        const allRowsHead = []
        const rowItem = [
          { text: vm.$t('elearning_config.fiscal_year'), style: 'td', alignment: 'right' },
          { text: ':', style: 'td', alignment: 'center' },
          { text: (i18n.locale === 'bn') ? data.fiscal_year_bn : data.fiscal_year, style: 'td', alignment: 'left' },
          { text: vm.$t('elearning_config.organization'), style: 'td', alignment: 'right' },
          { text: ':', style: 'td', alignment: 'center' },
          { text: (i18n.locale === 'bn') ? data.org_bn : data.org, style: 'td', alignment: 'left' }
        ]
        allRowsHead.push(rowItem)
        if (data.training_type && data.training_category) {
          const rowItem = [
            { text: vm.$t('elearning_config.training_type'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? data.training_type_bn : data.training_type, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_config.training_category'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? data.training_category_bn : data.training_category, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (data.training_title && data.circular_memo_no) {
          const rowItem = [
            { text: vm.$t('elearning_config.training_title'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? data.training_title_bn : data.training_title, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: data.circular_memo_no, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (data.batch_no) {
          const rowItem = [
            { text: vm.$t('elearning_iabm.batch_no'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? data.batch_name_bn : data.batch_name, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_iabm.training_start_date'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: dateFormat(data.training_start_date), style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (data.training_end_date) {
          const rowItem = [
            { text: vm.$t('elearning_iabm.training_end_date'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: dateFormat(data.training_end_date), style: 'td', alignment: 'left', colSpan: 4 },
            {},
            {},
            {}
          ]
          allRowsHead.push(rowItem)
        }
        // if (search.trainer_name) {
        //   const rowItem = [
        //     { text: vm.$t('externalTraining.trainer_name'), style: 'td', alignment: 'right' },
        //     { text: ':', style: 'td', alignment: 'center' },
        //     { text: (i18n.locale === 'bn') ? search.trainer_name_bn : search.trainer_name, style: 'td', alignment: 'left' }
        //   ]
        //   allRowsHead.push(rowItem)
        // }
        if (allRowsHead.length > 0) {
          pdfContent.push({
            table: {
              widths: ['18%', '2%', '30%', '18%', '2%', '30%'],
              body: allRowsHead
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          })
        }
        pdfContent.push({ text: vm.$t('elearning_tim.attendance') + ' ' + vm.$t('globalTrans.list'), style: 'hh', alignment: 'center' })
        const firstHeader = [
            { text: vm.$t('globalTrans.sl_no'), style: 'th1', rowSpan: 3, alignment: 'center' },
            { text: vm.$t('elearning_tim.trainee'), style: 'th1', rowSpan: 3, alignment: 'center' },
            { text: vm.$t('globalTrans.designation'), style: 'th', rowSpan: 3, alignment: 'center' },
            { text: vm.$t('elearning_tim.posting_office'), style: 'th', rowSpan: 3, alignment: 'center' },
            { text: vm.$t('globalTrans.mobile'), style: 'th1', rowSpan: 3, alignment: 'center' },
            { text: vm.$t('globalTrans.date'), style: 'th1', colSpan: dateList.length * 2, alignment: 'center' }
        ]
        if (dateList.length >= 1) {
          for (let i = 0; i < dateList.length; i++) {
            if (i === 0) {
              firstHeader.push(
                {}
              )
            } else {
              firstHeader.push(
                {},
                {}
              )
            }
          }
        }
        const allRows = [
          firstHeader
        ]
        const secondHeader = [
          {},
          {},
          {},
          {},
          {}
        ]
        const widthList = ['5%', '12%', '12%', '18%', '10%']
        dateList.forEach(dateItem => {
          secondHeader.push(
            { text: (dateItem) ? dateFormat(dateItem.text) : '', style: 'th1', alignment: 'center', bold: true, colSpan: 2 },
            {}
          )
          widthList.push('*', '*')
        })
        allRows.push(secondHeader)
        const thirdHeader = [
          {},
          {},
          {},
          {},
          {}
        ]
        dateList.forEach(dateItem => {
          thirdHeader.push(
            { text: vm.$t('globalTrans.morning'), style: 'th1', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.day'), style: 'th1', alignment: 'center', bold: true }
          )
        })
        allRows.push(thirdHeader)
        // table body
        detailsData.filter((data, optionIndex) => {
          const rowItem = [
            { text: vm.$n(optionIndex + 1), style: 'td1', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.name_bn : data.name, style: 'td1', alignment: 'center' },
            { text: (vm.$i18n.locale === 'bn' ? data.designation_name_bn : data.designation_name), style: 'td', alignment: 'center' },
            { text: ((vm.$i18n.locale === 'bn' ? data.office_name_bn : data.office_name)) + ', ' + ((vm.$i18n.locale === 'bn' ? data.org_name_bn : data.org_name)), style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.mobile : data.mobile, style: 'td1', alignment: 'center' }
          ]
          dateList.forEach((field, index1) => {
            rowItem.push(
              { text: vm.attendenMorningInfo(data, field.text), alignment: 'center', style: 'td1' },
              { text: vm.attendenDayInfo(data, field.text), alignment: 'center', style: 'td1' }
            )
          })
          allRows.push(rowItem)
        })
        pdfContent.push({
          table: {
            headerRows: 3,
            widths: widthList,
            body: allRows
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer' })
        pdfContent.push({ text: '', style: 'fertilizer' })
        if (i18n.locale === 'en') {
          pdfContent.push({
            text: [
                vm.$t('tpm_report.report_footer1') + ' ',
                { text: vm.$t('tpm_report.idsdp'), bold: true },
                ' ' + vm.$t('tpm_report.report_footer2')
            ],
            style: 'td',
            alignment: 'center'
          })
        }
        if (i18n.locale === 'bn') {
          pdfContent.push({
            text: [
                { text: vm.$t('tpm_report.idsdp'), bold: true },
                ' ' + vm.$t('tpm_report.report_footer1') + ' ' + vm.$t('tpm_report.report_footer2')
            ],
            style: 'td',
            alignment: 'center'
          })
        }
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'landscape',
        footer: function (currentPage, pageCount) {
          return [
            // {
            //   canvas: [
            //     {
            //       type: 'line',
            //       x1: 20,
            //       y1: 0,
            //       x2: 595 - 20,
            //       y2: 0, // Adjust x2 to the page width minus margin
            //       lineWidth: 0.5,
            //       lineColor: 'black'
            //     }
            //   ]
            // },
            {
              columns: [
                {
                  text: vm.$t('tpm_report.page') + ' ' + vm.$n(currentPage.toString()) + ' / ' + vm.$n(pageCount),
                  alignment: 'left',
                  style: 'footerStyle'
                },
                {
                  text: vm.$t('tpm_report.idsdp'),
                  alignment: 'center',
                  style: 'footerStyle'
                },
                {
                  text: vm.$t('tpm_report.footer_date_time') + ' ' + new Date().toLocaleDateString('bn-BD') + ', ' + new Date().toLocaleTimeString('bn-BD', { hour12: false }),
                  alignment: 'right',
                  style: 'footerStyle'
                }
              ],
              margin: [10, 5] // Adjust the margin as needed
            }
          ]
        },
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          footerStyle: {
            fontSize: 8,
            margin: [20, 5, 20, 5]
          },
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          th1: {
            fontSize: (i18n === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          td1: {
            fontSize: (i18n === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          header: {
              fontSize: 16,
              bold: true,
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          fertilizer: {
            fontSize: 10,
            margin: [10, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 0]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 4]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
