<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('tpm_report.training_course_wise_attendance_report') }} {{ $t('globalTrans.search') }}</h4>
            </template>
          <template v-slot:body>
            <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                      <b-overlay :show="load">
                        <b-row>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="fiscal_year_id"
                            >
                              <template v-slot:label>
                                {{$t('globalTrans.fiscal_year')}}
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.fiscal_year_id"
                                :options="fiscalYearList"
                                id="fiscal_year_id"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                          </b-col>
                          <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1" >
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.organization')}}
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.org_id"
                                :options="orgList"
                                id="org_id"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_type_id"
                            >
                              <template v-slot:label>
                                {{$t('elearning_config.training_type')}}
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.training_type_id"
                                :options="trainingTypeList"
                                id="training_type_id"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_category_id"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_category')}}
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.training_category_id"
                                  :options="trainingCategoryList"
                                  id="training_category_id"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                            </b-form-group>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_title_id"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_title')}}
                                </template>
                                <b-form-select
                                  plain
                                  v-model="formData.training_title_id"
                                  :options="trainingTitleList"
                                  id="training_title_id"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                            </b-form-group>
                          </b-col>
                          <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="batch_no"
                            >
                              <template v-slot:label>
                                {{$t('elearning_iabm.batch_no')}}
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.batch_no"
                                :options="batchList"
                                id="batch_no"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                          </b-col>
                          <!-- <b-col lg="6" sm="12">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="circular_memo_no"
                            >
                              <template v-slot:label>
                                {{$t('elearning_iabm.circular_memo_no')}}
                              </template>
                              <b-form-select
                                plain
                                v-model="formData.circular_memo_no"
                                :options="circularList"
                                id="circular_memo_no"
                                >
                                <template v-slot:first>
                                    <b-form-select-option value=''>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                </template>
                                </b-form-select>
                            </b-form-group>
                          </b-col> -->
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Circular Memo No" vid="circular_memo_no"  rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="circular_memo_no"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    disabled
                                    v-model="formData.circular_memo_no"
                                    :options="circularList"
                                    id="circular_memo_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                    <b-form-select-option value='0'>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback d-block">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Start Date" vid="training_start_date">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_start_date"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('elearning_iabm.training_start_date') }}
                                </template>
                                <b-form-input
                                    class="fromDate"
                                    disabled
                                    v-model="formData.training_start_date"
                                    :placeholder="$t('globalTrans.select_date')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training End Date" vid="training_end_date">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_end_date"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('elearning_iabm.training_end_date') }}
                                </template>
                                <b-form-input
                                    disabled
                                    class="fromDate"
                                    v-model="formData.training_end_date"
                                    :placeholder="$t('globalTrans.select_date')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                      </b-overlay>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                          &nbsp;
                        </div>
                       </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
            </b-row>
          </template>
        </iq-card>
        <b-row>
            <b-col md="12" v-if="showData">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('tpm_report.training_course_wise_attendance_report') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new" @click="pdfExport">
                            <i class="fas fa-print"></i>{{ $t('globalTrans.print') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <div style="border: 2px solid;margin:10px;padding:10px">
                                <b-row>
                                    <b-col v-for="(info, index) in dateList" :key="index">
                                        <b-form-checkbox
                                        :id="'checkbox-' + info.text"
                                        v-model="info.checked"
                                        :name="'checkbox-' + info.text"
                                        value=2
                                        unchecked-value=1
                                        @change="checkCount(info)"
                                        >
                                        {{info.text | dateFormat}}
                                        </b-form-checkbox>
                                    </b-col>
                                </b-row>
                            </div>
                            <div style="border: 2px solid;margin:10px;padding:10px">
                                <b-row>
                                    <b-col>
                                        <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="formData.org_id">
                                            {{ $t('tpm_report.training_course_wise_attendance_report') }}
                                        </list-report-head>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                      <div class="text-center">
                                        <b-row>
                                          <b-col>
                                            <table style="width:100%;color:black;">
                                              <tr>
                                                <td align="right" style="width:18%">{{ $t('elearning_config.fiscal_year') }}</td>
                                                <td align="center" style="width:2%">:</td>
                                                <td align="left" style="width:30%">{{ ($i18n.locale==='bn') ? formData.fiscal_year_bn : formData.fiscal_year }}</td>
                                                <td align="right" style="width:18%">{{ $t('elearning_config.organization') }}</td>
                                                <td align="center" style="width:2%">:</td>
                                                <td align="left" style="width:30%">{{ ($i18n.locale==='bn') ? formData.org_bn : formData.org }}</td>
                                              </tr>
                                              <tr>
                                                <td align="right" style="width:18%">{{ $t('elearning_config.training_type') }}</td>
                                                <td align="center" style="width:2%">:</td>
                                                <td align="left" style="width:30%">{{ ($i18n.locale==='bn') ? formData.training_type_bn : formData.training_type }}</td>
                                                <td align="right" style="width:18%">{{ $t('elearning_config.training_category') }}</td>
                                                <td align="center" style="width:2%">:</td>
                                                <td align="left" style="width:30%">{{ ($i18n.locale==='bn') ? formData.training_category_bn : formData.training_category }}</td>
                                              </tr>
                                              <tr>
                                                <td align="right" style="width:18%">{{ $t('elearning_config.training_title') }}</td>
                                                <td align="center" style="width:2%">:</td>
                                                <td align="left" style="width:30%">{{ ($i18n.locale==='bn') ? formData.training_title_bn : formData.training_title }}</td>
                                                <td align="right" style="width:18%">{{ $t('elearning_iabm.circular_memo_no') }}</td>
                                                <td align="center" style="width:2%">:</td>
                                                <td align="left" style="width:30%">{{  formData.circular_memo_no }}</td>
                                              </tr>
                                              <tr>
                                                <td align="right" style="width:18%">{{ $t('elearning_iabm.batch_no') }}</td>
                                                <td align="center" style="width:2%">:</td>
                                                <td align="left" style="width:30%">{{ ($i18n.locale==='bn') ? formData.batch_name_bn : formData.batch_name }}</td>
                                                <td align="right" style="width:18%">{{ $t('elearning_iabm.training_start_date') }}</td>
                                                <td align="center" style="width:2%">:</td>
                                                <td align="left" style="width:30%">{{ formData.training_start_date | dateFormat }}</td>
                                              </tr>
                                              <tr>
                                                <td align="right" style="width:18%">{{ $t('elearning_iabm.training_end_date') }}</td>
                                                <td align="center" style="width:2%">:</td>
                                                <td align="left" style="width:30%">{{ formData.training_end_date | dateFormat }}</td>
                                              </tr>
                                            </table>
                                          </b-col>
                                        </b-row>
                                      </div>
                                    </b-col>
                                  </b-row>
                                <b-row>
                                    <b-col lg="12" sm="12" v-if="details.length > 0">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                        <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                                            <b-row>
                                            </b-row>
                                            <b-row>
                                            <div class="col-md-12">
                                                <!-- <fieldset class="p-2 w-100"> -->
                                                    <h4 class="card-title">{{ $t('elearning_tim.attendance') }} {{ $t('globalTrans.list') }}</h4>
                                                <!-- <legend class="px-2 w-50 shadow-sm">{{ $t('elearning_tim.attendance') }} {{ $t('globalTrans.list') }}</legend> -->
                                                    <!-- <b-table-simple hover bordered responsive> -->
                                                    <b-table-simple hover bordered responsive  v-if="dateList.filter(item1 => item1.checked === '2').length>0">
                                                    <b-thead class="thead">
                                                        <b-tr>
                                                          <b-th rowspan="3" class="text-center">{{$t('globalTrans.sl_no')}}</b-th>
                                                          <b-th rowspan="3">{{$t('elearning_tim.trainee')}}</b-th>
                                                          <b-th rowspan="3" class="text-center" style="vertical-align:middle">{{$t('globalTrans.designation')}}</b-th>
                                                          <b-th rowspan="3" class="text-center" style="vertical-align:middle">{{$t('elearning_tim.posting_office')}}</b-th>
                                                          <b-th rowspan="3">{{$t('globalTrans.mobile')}}</b-th>
                                                          <b-th style="width:10%" class="text-center" :colspan="dateList.length * 2">{{ $t('globalTrans.date') }}</b-th>
                                                        </b-tr>
                                                        <b-tr>
                                                            <slot v-for="(field) in dateList.filter(item1 => item1.checked === '2')">
                                                                <b-th class="text-center" colspan="2">
                                                                    {{ field.text | dateFormat }}
                                                                </b-th>
                                                            </slot>
                                                        </b-tr>
                                                        <b-tr>
                                                          <slot v-for="(field) in dateList.filter(item1 => item1.checked === '2')">
                                                            <b-th class="text-center" :id="'morning-' + field.value">
                                                              {{$t('globalTrans.morning')}}
                                                            </b-th>
                                                            <b-th class="text-center" :id="'day-' + field.value">
                                                              {{$t('globalTrans.day')}}
                                                            </b-th>
                                                          </slot>
                                                        </b-tr>
                                                    </b-thead>
                                                    <b-tr v-for="(train,index) in details" :key="index">
                                                        <b-td class="text-center">{{$n(index+1)}}</b-td>
                                                        <b-td>
                                                        {{ ($i18n.locale==='bn') ? train.name_bn : train.name }}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                          {{ ($i18n.locale==='bn') ? train.designation_name_bn : train.designation_name }}
                                                        </b-td>
                                                        <b-td class="text-center">
                                                          {{ (($i18n.locale==='bn') ? train.office_name_bn : train.office_name) + ', ' + (($i18n.locale==='bn') ? train.org_name_bn : train.org_name) }}
                                                        </b-td>
                                                        <b-td>{{ train.mobile }}
                                                        <small v-if="detailsErrors && detailsErrors['details.' + index + '.mobile']" class="text-danger">
                                                            {{ detailsErrors['details.'+index+'.mobile'] }}
                                                        </small>
                                                        </b-td>
                                                        <slot v-for="(field) in dateList.filter(item1 => item1.checked === '2')">
                                                          <b-td class="text-center">
                                                            {{ attendenMorningInfo(train, field.text) }}
                                                            <!-- <slot v-if="details[index].dateList[index1].morning">
                                                                {{$t('elearning_tim.present')}}
                                                            </slot>
                                                            <slot v-else>
                                                                {{$t('elearning_tim.absent')}}
                                                            </slot> -->
                                                          </b-td>
                                                          <b-td class="text-center">
                                                            {{ attendenDayInfo(train, field.text) }}
                                                            <!-- <slot v-if="details[index].dateList[index1].day">
                                                                {{$t('elearning_tim.present')}}
                                                            </slot>
                                                            <slot v-else>
                                                                {{$t('elearning_tim.absent')}}
                                                            </slot> -->
                                                          </b-td>
                                                        </slot>
                                                    </b-tr>
                                                    <template v-if="details.length === 0">
                                                        <tr>
                                                        <th :colspan="dateList.length" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                                                        </tr>
                                                    </template>
                                                    </b-table-simple>
                                                <!-- </fieldset> -->
                                            </div>
                                            </b-row>
                                        <!-- </b-overlay> -->
                                        </b-form>
                                    </ValidationObserver>
                                    </b-col>
                                </b-row>
                                <b-row>
                                  <b-col class="text-center" v-if="$i18n.locale==='bn' && details.length > 0"><strong>{{ $t('tpm_report.idsdp') }}</strong> {{ $t('tpm_report.report_footer1') }} {{ $t('tpm_report.report_footer2') }}</b-col>
                                  <b-col class="text-center" v-if="$i18n.locale==='en' && details.length > 0">{{ $t('tpm_report.report_footer1') }} <strong>{{ $t('tpm_report.idsdp') }}</strong> {{ $t('tpm_report.report_footer2') }}</b-col>
                                </b-row>
                            </div>
                        </b-overlay>
                    </template>
                </iq-card>
                <!-- <pre>{{ dateList }}</pre> -->
            </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
// import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ValidationObserver } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { attendanceStore, trainingModuleWiseTrainer, circularWiseAttendanceApplicationList, circularWiseAttendanceList, circularList } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import flatpickr from 'flatpickr'
import ExportPdf from './export_pdf_attendence_details'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    // ValidationProvider,
    ListReportHead
  },
  created () {
    this.getCircularList()
    this.formData = Object.assign({}, this.formData, {
        fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
      })
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.formData.org_id = this.$store.state.Auth.authUser.org_id
      if (this.$store.state.Auth.authUser.is_org_admin !== 1 && parseInt(this.$store.state.Auth.authUser.org_id) === 12) {
        this.formData.coordinator_id = this.$store.state.Auth.authUser.user_id
      }
    }
    // this.getCircularMemoNo(this.$route.query.circularMemoNo)
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      showData: false,
      loading: false,
      load: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        circular_memo_no: '',
        batch_no: 0,
        org: '',
        org_bn: '',
        office: '',
        office_bn: '',
        office_type: '',
        office_type_bn: '',
        fiscal_year: '',
        fiscal_year_bn: '',
        training_category: '',
        training_category_bn: '',
        training_type: '',
        training_type_bn: '',
        training_title: '',
        training_title_bn: '',
        fiscal_year_id: 0,
        training_type_id: 0,
        training_category_id: 0,
        training_title_id: 0,
        org_id: 0,
        office_id: 0,
        office_type_id: 0,
        course_id: 0,
        course_module_id: 0,
        training_date: '',
        trainer_id: 0,
        registration_for: 2,
        sort_status: 2,
        training_start_date: '',
        training_end_date: '',
        details: []
      },
      trainingCalendar: {
        training_start_date: '',
        training_end_date: ''
      },
      allBatchListData: [],
      batchList: [],
      details: [],
      trainingTypeList: [],
      officeTypeList: [],
      detailsErrors: [],
      trainerLoading: false,
      circularList: [],
      circularLoading: false,
      officeList: [],
      courseModuleList: [],
      courseList: [],
      trainingCategoryList: [],
      attachments_errors: [],
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainerListData: [],
      trainingTitleList: [],
      circularWiseAttendanceList: [],
      detailErrors: [],
      dateList: []
    }
  },
  computed: {
    trainerList: function () {
      const listObject = this.trainerListData
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text_en }
        }
      })
      return tmpList
    },
    venus: function () {
      return this.$store.state.TrainingElearning.commonObj.venus.filter(item => item.status === 1)
    },
    trainingQuarterSetupList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingQuarterSetupList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    allBatchList () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
        { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
        { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
        { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
        { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
        { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
        { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
        { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
        { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
        { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
      ]
    }
  },
  watch: {
    'formData.org_id': function (newValue) {
      this.trainingTypeList = this.getTypeList(newValue)
    },
    'formData.training_type_id': function (newValue) {
      this.trainingCategoryList = this.getCategoryList(newValue)
    },
    'formData.training_category_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    },
    'formData.training_title_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getCircularList()
      }
    },
    'formData.fiscal_year_id': function (newValue) {
      this.getCircularList()
    },
    'formData.circular_memo_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => String(allBatchListData.circular_memo_no) === String(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.formData.batch_no = allBatchListData.batch_no
        }
      }
    },
    'formData.batch_no': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const allBatchListData = this.allBatchListData.find(allBatchListData => parseInt(allBatchListData.batch_no) === parseInt(newVal))
        if (typeof allBatchListData !== 'undefined') {
          this.formData.circular_memo_no = allBatchListData.circular_memo_no
          this.formData.training_start_date = allBatchListData.training_start_date
          this.formData.training_end_date = allBatchListData.training_end_date
        }
      }
    },
    'formData.course_id': function (newValue) {
      this.courseModuleList = this.getCourseModuleList(newValue)
      this.getTrainerList(newValue)
    },
    'formData.trainer_id': function (newValue) {
      this.getAttedenceList()
    },
    'formData.training_date': function (newValue) {
      this.getAttedenceList()
    }
  },
  methods: {
    attendenDayInfo (item, date) {
      const saveObj = this.circularWiseAttendanceList.find(circularWiseAttendance => circularWiseAttendance.training_application_id === item.id && circularWiseAttendance.training_date === date)

      if (typeof saveObj !== 'undefined') {
        if (saveObj.day === 1) {
          return this.$i18n.locale === 'bn' ? 'উপস্থিতি' : 'Present'
        } else {
          return this.$i18n.locale === 'bn' ? 'অনুপস্থিত' : 'Absent'
        }
      } else {
        return this.$i18n.locale === 'bn' ? 'অনুপস্থিত' : 'Absent'
      }
    },
    attendenMorningInfo (item, date) {
      const saveObj = this.circularWiseAttendanceList.find(circularWiseAttendance => circularWiseAttendance.training_application_id === item.id && circularWiseAttendance.training_date === date)

      if (typeof saveObj !== 'undefined') {
        if (saveObj.morning === 1) {
          return this.$i18n.locale === 'bn' ? 'উপস্থিতি' : 'Present'
        } else {
          return this.$i18n.locale === 'bn' ? 'অনুপস্থিত' : 'Absent'
        }
      } else {
        return this.$i18n.locale === 'bn' ? 'অনুপস্থিত' : 'Absent'
      }
    },
        // if (saveObj.morning === 1) {
        //   if (typeof saveObj !== 'undefined') {
        //     return this.$i18n.locale === 'bn' ? 'উপস্থিতি' : 'Present'
        //   } else {
        //     return this.$i18n.locale === 'bn' ? 'অনুপস্থিত' : 'Absent'
        //   }
        // }
        // if (typeof saveObj !== 'undefined') {
        //       items.morning = saveObj.morning
        //       items.day = saveObj.day
        //       items.checkData = true
        //       items.text = dateList.text
        //   } else {
        //       items.morning = false
        //       items.day = false
        //       items.checkData = false
        //       items.text = dateList.text
        //   }
      // }
    // },
    pdfExport () {
        const reportTitle = this.$t('tpm_report.training_course_wise_attendance_report')
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', this.formData, reportTitle, this.formData, this, this.details, this.dateList.filter(item1 => item1.checked === '2'))
    },
    async searchData () {
        this.circularWiseAttendance()
    },
    getTypeList (orgId) {
      const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      if (orgId) {
        return type.filter(item => item.org_id === parseInt(orgId))
      }
      return type
    },
    batchInfo () {
      const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.formData.training_title_id))
      const batchList = []
      batchData.map((obj, index) => {
        if (obj.batch_no) {
          const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
          if (typeof isThere !== 'undefined') {
            batchList.push(isThere)
          }
        }
      })
      this.batchList = batchList
      if (this.batchList.length === 1) {
        this.formData.batch_no = 1
      }
    },
    circluarList () {
      const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.formData.training_title_id))
      const tmpList = listObject.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        } else {
          return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
        }
      })
      this.circularList = tmpList
    },
    async getCircularList () {
      this.circularLoading = true
      const serchData = {
        fiscal_year_id: this.formData.fiscal_year_id,
        training_type_id: this.formData.training_type_id,
        training_category_id: this.formData.training_category_id,
        training_title_id: this.formData.training_title_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
      if (!result.success) {
      } else {
        this.allBatchListData = result.batchList
        this.batchInfo()
        this.circluarList()
      }
      this.circularLoading = false
    },
    getCustomDataSearch () {
        const orgObj = this.$store.state.commonObj.organizationProfileList.find(organizationProfileList => organizationProfileList.value === parseInt(this.formData.org_id))
        if (typeof orgObj !== 'undefined') {
          this.formData.org = orgObj.text_en
          this.formData.org_bn = orgObj.text_bn
        } else {
          this.formData.org = ''
          this.formData.org_bn = ''
        }
        const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(fiscalYearList => fiscalYearList.value === parseInt(this.formData.fiscal_year_id))
        if (typeof fiscalYearObj !== 'undefined') {
          this.formData.fiscal_year = fiscalYearObj.text_en
          this.formData.fiscal_year_bn = fiscalYearObj.text_bn
        //   this.search.fiscal_year_bn = fiscalYearObj.text_bn
        } else {
          this.formData.fiscal_year = ''
          this.formData.fiscal_year_bn = ''
        }
        const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(trainingCategoryList => trainingCategoryList.value === parseInt(this.formData.training_title_id))
        if (typeof trainingTitleObj !== 'undefined') {
          this.formData.training_title = trainingTitleObj.text_en
          this.formData.training_title_bn = trainingTitleObj.text_bn
        } else {
          this.formData.training_title = ''
          this.formData.training_title_bn = ''
        }
        const batchObj = this.allBatchList.find(item => item.value === this.formData.batch_no)
        this.formData.batch_name = typeof batchObj !== 'undefined' ? batchObj.text_en : ''
        this.formData.batch_name_bn = typeof batchObj !== 'undefined' ? batchObj.text_bn : ''
        const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(this.formData.training_type_id))
        if (typeof trainingTypeObj !== 'undefined') {
          this.formData.training_type = trainingTypeObj.text_en
          this.formData.training_type_bn = trainingTypeObj.text_bn
        } else {
          this.formData.training_type = ''
          this.formData.training_type_bn = ''
        }
        const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(trainingCategoryList => trainingCategoryList.value === parseInt(this.formData.training_category_id))
        if (typeof trainingCategoryObj !== 'undefined') {
          this.formData.training_category = trainingCategoryObj.text_en
          this.formData.training_category_bn = trainingCategoryObj.text_bn
        } else {
          this.formData.training_category = ''
          this.formData.training_category_bn = ''
        }
        const ofcTypeObj = this.$store.state.commonObj.officeTypeList.find(ofcType => ofcType.value === parseInt(this.formData.office_type_id))
        if (typeof ofcTypeObj !== 'undefined') {
          this.formData.ofc_type_name = ofcTypeObj.text_en
          this.formData.ofc_type_name_bn = ofcTypeObj.text_bn
        } else {
          this.formData.ofc_type_name = ''
          this.formData.ofc_type_name_bn = ''
        }
        const ofcObj = this.$store.state.commonObj.officeList.find(ofcList => ofcList.value === parseInt(this.formData.office_id))
        if (typeof ofcObj !== 'undefined') {
          this.formData.ofc_name = ofcObj.text_en
          this.formData.ofc_name_bn = ofcObj.text_bn
        } else {
          this.formData.ofc_name = ''
          this.formData.ofc_name_bn = ''
        }
      },
    checkCount (item) {
        const total = this.dateList.filter(item1 => item1.checked === '2')
        if (total.length > 4) {
            this.$toast.error({
            title: 'Error',
                message: this.$i18n.locale === 'bn' ? 'সর্বোচ্চ চারFourটি তারিখ যোগ করা যাবে!' : 'Maximum Four dates can be added!'
            })
            item.checked = false
            item.checAll = false
        }
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.training_type_id === typeId)
       }
       return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_category_id === categoryId)
      }
      return trainingTitleList
    },
    getCourseList (circularMemoNo) {
      const courseList = this.$store.state.TrainingElearning.commonObj.courseList
      if (circularMemoNo) {
        return courseList.filter(item => item.circular_memo_no === String(circularMemoNo))
      }
      return courseList
    },
    getCourseModuleList (courseId) {
      const courseModuleList = this.$store.state.TrainingElearning.commonObj.courseModuleList
      if (courseId) {
        return courseModuleList.filter(item => item.course_id === courseId)
      }
      return courseModuleList
    },
    async getAttedenceList () {
       this.getCustomDataSearch()
      if (this.formData.circular_memo_no) {
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularWiseAttendanceApplicationList, this.formData)
        if (result.success) {
        //   var temp = []
          const datas = result.data.map(item => {
            // var fertilizerAgent = []
            const desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
            const desigData = {}
            if (item.designation_id && parseInt(item.not_here_designation) === 0 && parseInt(item.profession_type) === 1) {
              if (typeof desigObj !== 'undefined') {
                desigData.designation_name = desigObj.text_en
                desigData.designation_name_bn = desigObj.text_bn
              } else {
                desigData.designation_name = ''
                desigData.designation_name_bn = ''
              }
            } else {
                desigData.designation_name = item.designation_en
                desigData.designation_name_bn = item.designation_bn
            }
            // this.dateList.forEach((dateList, dateListIndex) => {
            //     const saveObj = this.circularWiseAttendanceList.find(circularWiseAttendance => circularWiseAttendance.training_application_id === item.id && circularWiseAttendance.training_date === dateList.text)
            //     const items = {}
            //     items.training_application_id = item.id
            //     if (typeof saveObj !== 'undefined') {
            //         items.morning = saveObj.morning
            //         items.day = saveObj.day
            //         items.checkData = true
            //         items.text = dateList.text
            //     } else {
            //         items.morning = false
            //         items.day = false
            //         items.checkData = false
            //         items.text = dateList.text
            //     }
            //     fertilizerAgent.push(items)
            // })
            // const newData = {
            //     dateList: fertilizerAgent
            // }
            const orgObj = this.$store.state.commonObj.organizationProfileList.find(org => org.value === parseInt(item.professional_org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
              orgData.org_name = orgObj.text_en
              orgData.org_name_bn = orgObj.text_bn
            } else {
              orgData.org_name = ''
              orgData.org_name_bn = ''
            }
            const officeObj = this.$store.state.commonObj.officeList.find(office => office.value === parseInt(item.office_id))
            const officeData = {}
            if (typeof officeObj !== 'undefined') {
              officeData.office_name = officeObj.text_en
              officeData.office_name_bn = officeObj.text_bn
            } else {
              officeData.office_name = ''
              officeData.office_name_bn = ''
            }
            // temp.push(fertilizerAgent)
            return Object.assign({}, item, desigData, orgData, officeData)
          })
          this.details = datas
          this.loading = false
        } else {
          this.details = []
          this.loading = false
        }
      }
    },
    checkData (item) {
        this.details.forEach((detail, detailIndex) => {
            detail.dateList.forEach((dateList, dateListIndex) => {
                if (dateList.text === item.text) {
                    if (item.checkAll === '2') {
                        dateList.checkData = true
                    } else {
                        dateList.checkData = false
                    }
                }
            })
        })
    },
    async circularWiseAttendance () {
        this.showData = true
      this.loading = true
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularWiseAttendanceList, this.formData)
      if (result.success) {
        this.circularWiseAttendanceList = result.data
        const listData = result.aryRange.map(item => {
          const updateData = {}
          updateData.checked = false
          updateData.checkAll = false
          updateData.text = item
          return Object.assign({}, updateData)
        })
        this.dateList = listData
      } else {
        this.circularWiseAttendanceList = []
      }
      this.getAttedenceList()
      this.loading = false
    },
    async getTrainerList (courseId) {
    //   this.loading = true
      this.formData.trainer_id = 0
      this.trainerListData = []
      this.trainerLoading = true
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingModuleWiseTrainer + '/' + courseId)
      if (!result.success) {
        this.trainerListData = []
        // this.loading = false
      } else {
        this.trainerListData = result.data
        // this.loading = false
      }
      if (result.data.length > 0) {
        this.formData.trainer_id = result.data[0].value
      }
      this.trainerLoading = false
    },
    // async getCircularMemoNo (getCircularMemoNo) {
    // //   this.loading = true
    //   this.load = true
    //   const params = {
    //     circular_memo_no: getCircularMemoNo,
    //     table: 'tpm_training_calendar'
    //   }
    //   const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublication, params)
    //   if (!result.success) {
    //     this.formData.fiscal_year_id = 0
    //     this.formData.org_id = 0
    //     this.formData.office_id = 0
    //     this.formData.office_type_id = 0
    //     this.formData.training_title_id = 0
    //     this.formData.training_type_id = 0
    //     this.formData.training_category_id = 0
    //     this.courseList = []
    //     this.trainingCalendar = []
    //     this.formData.training_start_date = ''
    //     this.formData.training_end_date = ''
    //   } else {
    //     this.lcErrorMsg = ''
    //     const trData = result.data
    //     this.trainingCalendar = result.training_calendar
    //     this.formData.fiscal_year_id = trData.fiscal_year_id
    //     this.formData.org_id = trData.org_id
    //     this.formData.office_id = trData.office_id
    //     this.formData.office_type_id = trData.office_type_id
    //     this.formData.training_title_id = trData.training_title_id
    //     this.formData.training_type_id = trData.training_type_id
    //     this.formData.training_category_id = trData.training_category_id
    //     this.formData.training_start_date = result.training_calendar.training_start_date
    //     this.formData.training_end_date = result.training_calendar.training_end_date
    //     this.courseList = this.getCourseList(getCircularMemoNo)
    //     const listData = result.aryRange.map(item => {
    //         // this.loading = false
    //         const updateData = {}
    //         updateData.checked = false
    //         updateData.checkAll = false
    //         updateData.text = item
    //         return Object.assign({}, updateData)
    //     })
    //     this.dateList = listData
    //     // this.circularWiseAttendance()
    //     this.load = false
    //   }
    //   this.load = false
    // },
    async createData () {
      this.loading = true
      let result = null
      this.formData.details = this.details
      if (this.formData.id) {
        // formData.append('_method', 'POST')
        // result = await RestApi.postData(trainingElearningServiceBaseUrl, `${trainingScheduleUpdate}/${this.formData.id}`, this.formData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, attendanceStore, this.formData)
      }
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        // this.getAttedenceList()
        this.$router.push('/training-e-learning-service/tim/attendance')
      } else {
        this.detailErrors = result.errors
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
